import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import typographySizes from 'src/assets/styles/typographySizes.js'
import colors from 'src/assets/styles/colors.js'
import mediaQuery from 'src/assets/styles/mediaQuery.js'
import distances from 'src/assets/styles/distances.js'

import percent from 'src/assets/svg/promotions-%.svg'
import arrow from 'src/assets/svg/promotions-arrow.svg'

import Img from 'src/assets/images/promotion.jpg'

import MainWrapper from 'src/components/global/MainWrapper.js'
import { Link } from 'gatsby'

const promotionsList = [
  {
    title:
      'Pobierz aplikację KIMIDORI. Zyskaj -20% na pierwsze zamówienie*  i korzystaj z kolejnych zniżek.',
    description:
      '*Rabat jest naliczany po złożeniu zamówienia, nie obejmuje oferty lunchowej i napojów, nie łączy się z innymi rabatami i promocjami. Nie obowiązuje 31.12 oraz 14.02.',
    go: true,
  },

  {
    title: 'Zamów i zapłać tylko 5 zł',
    description:
      'Za każde wydane w zamówieniu online 50zł, dobierz sushi za 5 zł z zakładki "ZAMÓW I ZAPŁAĆ TYLKO 5 ZŁ". Użyj kodu EXTRA5. Promocja obowiązuje przy zamówieniach online. ',
    go: true,
  },
  // {
  //   title: 'ZAMÓW I ZAPŁAĆ TYLKO 5 ZŁ',
  //   description:
  //     'Za każde wydane w zamówieniu online 50 zł, dobierz porcję sushi za 5 zł z kategorii "ZAMÓW I ZAPŁAĆ TYLKO 5 ZŁ". Rabat naliczy się po użyciu kodu EXTRA5.',
  //   go: true,
  // },
  {
    title: 'Program lojalnościowy.',
    description:
      'Zbieraj punkty i wymieniaj na nagrody. Za każde wydane 10 zł w zamówieniu online otrzymujesz 1 punkt. Punkty naliczają się tylko zarejestrowanym użytkownikom, po każdym złożonym zamówieniu. Ilość uzbieranych punktów widoczna będzie po zalogowaniu, w zakładce moje konto. Po zgromadzeniu odpowiedniej ilości punktów, dodaj wybraną nagrodę do koszyka.',
    go: true,
  },
]

const promotionsListLocal = [
  // {
  //   title: 'Poniedziałek',
  //   description: '30% zniżki na drugie danie główne.',
  //   go: false,
  // },

  // {
  //   title: 'Wtorek',
  //   description: '50% zniżki na drugą porcję sushi.',
  //   go: false,
  // },
  // {
  //   title: 'Środa',
  //   description: 'drugi deser gratis.',
  //   go: false,
  // },
  // {
  //   title: 'Czwartek',
  //   description: '2 przystawki w cenie 1.',
  //   go: false,
  // },
  // {
  //   title: 'Piątek',
  //   description: '30% zniżki na futo maki',
  //   go: false,
  // },
  // {
  //   title: 'Promocja na cały tydzień',
  //   description: '50% zniżki na drugie piwo/prosecco',
  //   go: false,
  // },
  {
    title: 'HAPPY HOURS  16-19 pon-piątek,  DRUGA ROLKA GRATIS',
    description:
      'dotyczy drugiej takiej samej porcji 4 szt. z kategorii california maki',
    go: false,
  },
  // {
  //   title: 'HAPPY HOURS  ',
  //   description: 'HAPPY HOURS  16-19 pon-piątek, drugi koktaj 50 % taniej',
  //   go: false,
  // },
  {
    title: 'Kimidori & Pink Vegan w Embargo',
    description:
      'Pobierz aplikację Embargo i zbieraj wirtualne pieczątki na karcie lojalnościowej Kimidori & Pink Vegan. Każdy lunch nagradzamy pieczątką. Zbierz 5 pieczątek, a przy kolejnej wizycie lunch otrzymasz w prezencie.',
    go: false,
  },
]

const StyledWrapper = styled(MainWrapper)`
  overflow-y: inherit !important;
`

const Wrapper = styled.section`
  background-color: ${colors.bright};
  padding: 30rem;
  border-radius: 40rem;
  position: relative;
  margin-bottom: 80rem;
  margin-top: 80rem;
  /* justify-content: space-between;

  justify-content: space-between;
  align-items: stretch; */

  @media (max-width: ${mediaQuery.tablet}) {
    border-radius: 9rem;
  }
`

const PromotionIcon = styled.img`
  position: absolute;
  right: 30rem;
  top: 0;
  transform: translateY(-50%);
  width: 80rem;
`

const ImageWrapper = styled.div`
  width: calc(41.66% - 15rem);
  height: calc(100% - 60rem);
  position: absolute;
  top: 30rem;
  background-image: url(${Img});
  background-size: cover;
  background-position: center;
  align-self: stretch;
  display: inline-block;

  border-radius: 23rem;
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`

const PromotionsWrapper = styled.div`
  width: calc(58.33% - 15rem);
  margin-left: calc(41.66% + 30rem);
  display: inline-block;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-left: 0;
  }
`

const Title = styled.h2`
  /* font-size: ${typographySizes.sm}rem; */
  font-size: ${typographySizes.m}rem;
  color: ${colors.darkGreen};
  margin-bottom: 20rem;
  line-height: 1;
  &.sec {
    margin-top: 50rem;
  }
`

// promotion

const PromotionTextWrapper = styled.div`
  max-width: 550rem;
  padding: 10rem 0rem;
  margin-right: auto;
`

const PromotionTitle = styled.h3`
  /* font-size: ${typographySizes.m}rem; */
  font-size: ${typographySizes.sm}rem;

  color: ${colors.dark};
  line-height: 1.1;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
`

const PromotionDescription = styled.p`
  font-size: ${typographySizes.s}rem;
  color: ${colors.darkGreen};
  line-height: 1.2;
  font-family: 'Poppins', sans-serif;
  margin-top: 10rem;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
`

const Arrow = styled.img`
  padding: 30rem 0rem;
  align-self: flex-end;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
`
const PromotionWrapper = styled(Link)`
  text-decoration: none;
  display: flex;
  width: 100%;

  justify-content: space-between;
  align-items: flex-end;

  &.animate {
    cursor: pointer;
  }
  position: relative;

  ::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 1rem;
    background-color: ${colors.dark};
    transition: all 0.5s 0.2s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &.animate {
    :hover::after {
      width: 0%;
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }

  ::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0%;
    height: 1rem;
    background-color: ${colors.darkGreen};
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }

  &.animate {
    :hover::before {
      width: 100%;
      transition: all 0.5s 0.1s cubic-bezier(0.65, 0, 0.35, 1);
    }

    :hover {
      ${Arrow} {
        transform: rotate(90deg);
        transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
      }
      ${PromotionTitle} {
        transform: translateY(-10rem);
        transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
      }
      ${PromotionDescription} {
        transform: translateY(-10rem);
        transition: all 0.5s 0.1s cubic-bezier(0.65, 0, 0.35, 1);
      }
    }
  }
`

const InfoText = styled.p`
  /* font-size: ${typographySizes.m}rem; */
  font-size: ${typographySizes.s}rem;

  color: ${colors.dark};
  line-height: 1.1;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  margin-top: 50rem;
`

const Promotion = props => {
  return (
    <PromotionWrapper
      data-sal="slide-up"
      data-sal-delay="200"
      data-sal-easing="ease"
      data-sal-duration="500"
      className="animate"
      to={props.go ? '/menu' : ''}
    >
      <PromotionTextWrapper>
        <PromotionTitle>{props.title}</PromotionTitle>
        <PromotionDescription>{props.description}</PromotionDescription>
      </PromotionTextWrapper>
      <Arrow src={arrow} />
      <Arrow />
    </PromotionWrapper>
  )
}

const PromotionLoc = props => {
  return (
    <PromotionWrapper
      data-sal="slide-up"
      data-sal-delay="200"
      data-sal-easing="ease"
      data-sal-duration="500"
    >
      <PromotionTextWrapper>
        <PromotionTitle>{props.title}</PromotionTitle>
        <PromotionDescription>{props.description}</PromotionDescription>
      </PromotionTextWrapper>
    </PromotionWrapper>
  )
}

const PromotionsSection = props => (
  <StyledWrapper id="promocje">
    <Wrapper>
      <PromotionIcon src={percent} />
      <ImageWrapper />
      <PromotionsWrapper>
        <Title
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="500"
        >
          promocje na wynos
        </Title>
        {promotionsList.map(promotion => (
          <Promotion
            go={promotion.go}
            title={promotion.title}
            description={promotion.description}
          />
        ))}
        <Title
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="500"
          className="sec"
        >
          promocje w lokalu
        </Title>
        {promotionsListLocal.map(promotion => (
          <PromotionLoc
            title={promotion.title}
            description={promotion.description}
          />
        ))}
        <InfoText>* rabaty nie łączą się</InfoText>
      </PromotionsWrapper>
    </Wrapper>
  </StyledWrapper>
)

export default PromotionsSection
